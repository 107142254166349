import React, { useEffect, useState } from "react";
import { getPastTwoWeeksMetrics, fetchUserDataByUid } from "api/db";
import Label from "components/Elements/Label";
import { DashboardContainer } from "components/Admin/styles";

export default function Active() {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    getPastTwoWeeksMetrics().then(snapshot => {
      let twoWeeksData = Object.values(snapshot.val());
      let thisWeek = twoWeeksData.slice(7, 14);

      let thisWeekUids = new Set();
      thisWeek.map(day => {
        Object.keys(day.uids).map(uid => {
          thisWeekUids.add(uid);
        });
      });

      let thisWeekUidsArray = Array.from(thisWeekUids);

      thisWeekUidsArray.map(uid => {
        fetchUserDataByUid(uid).once("value", function(snapshot) {
          let keyObject = { key: uid };
          let data = snapshot.val();
          const object = { ...data, ...keyObject };
          setUserData(users => users.concat(object));
        });
      });
    });
  }, []);

  return (
    <DashboardContainer>
      <Label>
        {" "}
        <a href="/admin/dashboard"> &larr; Back</a>{" "}
      </Label>
      <h2>Active Users</h2>

      {userData.length > 50 && (
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>image</th>
              <th>uid</th>
              <th>username</th>
              <th>email</th>
              <th>name</th>
              <th>joined</th>
            </tr>
          </thead>
          <tbody>
            {userData.map((user, index) => {
              return (
                <tr key={user && user.key && user.key}>
                  <td>{index}</td>
                  <td>
                    <img
                      src={user && user.photoURL && user.photoURL}
                      height="40px"
                    ></img>
                  </td>
                  <td>{user.key}</td>
                  <td>
                    {" "}
                    <a href={`/user/${user.id}`}>
                      @{user && user.id && user.id}
                    </a>
                  </td>
                  <td>{user && user.email_address && user.email_address}</td>
                  <td>{user && user.username && user.username}</td>
                  <td>{user && user.created_at && user.created_at}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </DashboardContainer>
  );
}
